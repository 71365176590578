<template>
	<div class="mapbaselayercontrol rounded elevation-1">
		<v-menu v-model="menu" :close-on-content-click="false" offset-x :open-on-hover="true">
			<template v-slot:activator="{ on, attrs }">
				<!-- <v-btn color="indigo" dark v-bind="attrs" v-on="on"> Menu as Popover </v-btn> -->
				<v-btn v-bind="attrs" v-on="on" id="image-btn" @click="buttonClicked" :disabled="!enableSatelliteView">
					<v-img class="image-content" :src="baseLayersControl[baseLayerId].src"></v-img>
				</v-btn>
			</template>
			<v-container class="custom-menu-container">
				<v-row no-gutters>
					<v-col v-for="n in this.numberOfLayers" :key="n" cols="12" sm="6">
						<div class="card-container" @click="cardClicked(n - 1)">
							<v-card class="card" max-width="500">
								<v-img :src="baseLayersControl[n - 1].src" width="70" height="70"></v-img>
							</v-card>
							<span class="cardTitle">{{ baseLayersControl[n - 1].title }}</span>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-menu>
	</div>
</template>

<script>
import ol2map from '@/components/operations/map/sections/map/subcomponents/ol2map';

/** Mixins */
import BaseLayersMixin from '@/mixins/BaseLayersMixin';

/** Constants */
import { BASEMAP_ID } from '@/components/operations/map/constants/layers';

export default {
	data: () => ({
		menu: false,
		baseLayerId: 0,
		isButtonClickable: false,
		enableSatelliteView: false
	}),

	mixins: [BaseLayersMixin],

	mounted() {
		this.satelliteViewMap();
	},

	computed: {
		numberOfLayers() {
			return this.baseLayersControl.length;
		}
	},

	watch: {
		enableSatelliteView(newVal) {
			this.enableSatelliteView = JSON.parse(newVal);
		}
	},

	methods: {
		buttonClicked() {
			this.incrementBaseLayerId();
			this.changeBaseLayer(this.baseLayerId);
		},

		cardClicked(id) {
			this.changeBaseLayer(id);
		},

		changeBaseLayer(id) {
			// Eliminar la capa base anterior
			this.baseLayerId = id;
			ol2map.removeLayerById(BASEMAP_ID);

			// Añadir la nueva capa base
			ol2map.insertLayerAtPosition(0, this.baseLayersControl[id].layer);
		},
		incrementBaseLayerId() {
			const baseLayeId = this.baseLayerId + 1;
			if (baseLayeId > this.numberOfLayers - 1) {
				this.baseLayerId = 0;
			} else {
				this.baseLayerId++;
			}
		},
		satelliteViewMap() {
			const opts = {
				model: 'portauthorityvariable',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'variable',
							op: 'eq',
							data: 'SATELLITE_VIEW_MAP'
						},
						{
							field: 'portauthorityid',
							op: 'eq',
							data: window.localStorage.getItem('workingPortAuthorityId')
						}
					]
				}
			};
			this.$puiRequests.postRequest(
				'/puisearch',
				opts,
				(response) => {
					if (response.data.data.length > 0) {
						this.enableSatelliteView = response.data.data[0].value;
					}
				},
				(error) => {
					console.error('Error al obtener los datos:', error);
					this.enableSatelliteView = false;
				}
			);
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../../styles/app-variables.pcss';
.mapbaselayercontrol {
	position: absolute;
	bottom: 1.5rem;
	z-index: 2;
	left: 40px;
	background-color: var(--negative);
	opacity: 0.9 !important;
}
#image-btn {
	background-color: white;
	color: #7e7e7e;
	height: 85px;
	width: 85px;
	padding: 10px;
	margin: 0px;
	border-radius: 50%;
	border: 1px solid #7e7e7e;
}
#image-btn .v-img {
	border: 2px solid #7e7e7e;
}
.image-content {
	object-fit: contain;
	border-radius: 5%;
	height: 75px;
	width: 75px;
}
.custom-menu-container {
	border: 1px solid #7e7e7e;
	border-radius: 5%;
	display: flex;
	justify-content: center;
	background-color: whitesmoke;
	padding: 2px;
	margin: 0px;
	width: fit-content;
}
.card {
	margin: 2px;
	padding: 0px;
}
.card-container {
	cursor: pointer;
}
.cardTitle {
	justify-content: center;
	font-size: 12px;
	display: flex;
}
</style>
